import React, { useEffect, useState } from 'react';
import { authHeader, getIconInactivityByName, history } from '../../_helpers';
import style from './InactivityForm.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { api } from '../../_helpers/api';
import { ClockCounterClockwise, DotsSixVertical, Trash } from 'phosphor-react';
import { FormControl, Select, MenuItem, ListItemIcon, Popover } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Visibility } from '@material-ui/icons';
import BefectiveTooltip from '../_shared/BefectiveTooltip';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';
import { ModalPreviewOutdoorGUI } from './ModalPreviewOutdoorGUI';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export function InactivityForm({ teamId = null, userDetails, alertActions, dataInactivity, getInactivityData, inactivityIcons, globalOutdoors = [] }) {

  const { t, i18n } = useTranslation('common');

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);

  const absence_period_messages = {
    pt: "Você esteve ausente por X minutos, das HH:MM até HH:MM.",
    es: "Estuviste ausente por X minutos, desde las HH:MM hasta las HH:MM.",
    en: "You were absent for X minutes, from HH:MM to HH:MM."
  }

  const tamplates_strings = {
    pt: 'Você esteve ausente por {{minutes}} minutos, das {{start_time}} até {{end_time}}',
    es: 'Estuviste ausente por {{minutes}} minutos, desde las {{start_time}} hasta las {{end_time}}',
    en: 'You were absent for {{minutes}} minutes, from {{start_time}} to {{end_time}}'
  }

  const [form, setForm] = useState({
    inactivity_time: 5,
    pop_up_message: '',
    absence_period_message: absence_period_messages[userDetails.language || i18n.language],
    responses: []
  })

  const [formError, setFormError] = useState({
    pop_up_message: '',
    responses: ''
  })

  const [currentItemResponse, setCurrentItemResponse] = useState(null);

  const [anchorElSelectIcon, setAnchorElSelectIcon] = useState(null);
  const openPopoverSelectIcon = Boolean(anchorElSelectIcon);
  const idPopoverSelectIcon = openPopoverSelectIcon ? 'popover-select-icon' : undefined;

  const handleClickSelectIcon = (event, response) => {
    setCurrentItemResponse(response);
    setAnchorElSelectIcon(event.currentTarget);
  };

  const handleCloseSelectIcon = () => {
    setAnchorElSelectIcon(null);
    setCurrentItemResponse(null);
  };

  const [anchorElConfirmRestoreOptions, setAnchorElConfirmRestoreOptions] = useState(null);
  const openConfirmRestoreOptions = Boolean(anchorElConfirmRestoreOptions);
  const idConfirmRestoreOptions = openConfirmRestoreOptions ? 'popover-confirm-restore-options' : undefined;

  const handleOpenConfirmRestoreOptions = (event) => {
    setAnchorElConfirmRestoreOptions(event.currentTarget);
  };

  const handleCloseConfirmRestoreOptions = () => {
    setAnchorElConfirmRestoreOptions(null)
  };
  
  const [openModalPreview, setOpenModalPreview] = useState(false)

  const handleCloseModalPreview = () => {
    setOpenModalPreview(false)
  }

  const handleOpenModalPreview = () => {
    setOpenModalPreview(true)
  }

  const handleConfirmRestoreOptions = async () => {
    try {

      const payload = teamId ? { type: 'group', group_id: teamId } : {team: 'global', group_id: null}
      const res = await api.put(`/settings/global/save-preset`, payload, { headers: authHeader() })
      
      if (res.status !== 200) {
        throw new Error("Error restore options")
      }

      await getInactivityData(false)
      handleCloseConfirmRestoreOptions()      
      alertActions.success(t('global_settings.inactivities.msg_success_restore_options'))

    }catch (error) {
      console.error(error)
      alertActions.error(t('global_settings.inactivities.msg_error_restore_options'))
    }
  }
  

  const saveInactivtForm = async () => {

    try {

      setIsSubmitting(true)

      const endpoint = teamId ? `/settings/groups/inactivity` : `/settings/global/save-inactivity`
      const payload = teamId ? { ...form, group_id: teamId } : {...form}
      const res = await api.post(endpoint, payload,{headers: authHeader()})

      if (res.status !== 200) {
        throw new Error("Error save inactivity")
      }

      await getInactivityData(false)
      // history.push('/global-settings/profiles')
      setIsSubmitting(false)
      alertActions.success(t('global_settings.inactivities.msg_success_save'))

    } catch (error) {
      console.error(error)
      setIsSubmitting(false)
      alertActions.error(t('global_settings.inactivities.msg_error_save'))
    }
  }

  const handleChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
    
    setFormError({
      ...formError,
      [name]: ''
    })
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.pop_up_message) {
      errors.pop_up_message = t("validation.required")
    }

    if (form.responses.length === 0) {
      errors.responses = t("validation.required")
    }

    return errors
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      saveInactivtForm()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])

  useEffect(() => {
    if (dataInactivity !== null) {
      setForm({
        inactivity_time: dataInactivity.inactivity_time ?? 5,
        pop_up_message: dataInactivity.pop_up_message,
        absence_period_message: absence_period_messages[userDetails.language || i18n.language],
        responses: dataInactivity.responses || []
      })
    }
  }, [dataInactivity])

  const handleChangeActivityOffline = (value, index) => {
    const responses = []

    form.responses.map((response, index2) => {
      
      if(index === index2){
        const outdoor = globalOutdoors.find(outdoor => outdoor.id === value)
        responses.push({
          ...response,
          global_outdoors_id: value,
          label: outdoor.label,
          reference_value: outdoor.reference_value
        })
      }else{
        responses.push(response)
      }
      
    })

    setForm({
      ...form,
      responses: responses
    })

  }

  const handleChangeClassification = (value, index) => {
    const responses = []

    form.responses.map((response, index2) => {
      
      if(index === index2){
        responses.push({
          ...response,
          reference_value: value
        })
      }else{
        responses.push(response)
      }
      
    })

    setForm({
      ...form,
      responses: responses
    })

  }

  const handleChangeIcon = (responseItem, newIcon) => {
    const responses = []
    form.responses.map((response, index) => {
      if (index === responseItem.aux_index) {
        responses.push({
          ...response,
          icon_id: newIcon.id,
          original_name: newIcon.original_name,
          url_dark: newIcon.url_dark,
          url_standard: newIcon.url_standard
        })
      }else{
        responses.push(response)
      }
    })
    setForm({
      ...form,
      responses: responses
    })
    handleCloseSelectIcon(null)
  }

  const handleRemoveResponse = (index) => {
    const responses = [...form.responses]
    responses.splice(index, 1)
    setForm({
      ...form,
      responses: responses
    })
  }

  const handleAddResponse = () => {
    const responses = [...form.responses]
    responses.push({
      id: null,
      global_outdoors_id: null,
      icon_id: null,
      reference_value: null,
      index: responses.length,
      label: null,
      original_name: null,
      url_dark: null,
      url_standard: null
    })
    setForm({
      ...form,
      responses: responses
    })
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    
    const { destination, source, draggableId: id } = result;
    
    if(destination.index === source.index) return;

    const newResponses = Array.from(form.responses);
    const [removed] = newResponses.splice(source.index, 1);
    newResponses.splice(destination.index, 0, removed);

    setForm({
      ...form,
      responses: newResponses.map((o, index) => ({...o, index: index + 1}))
    }); 
  };

  
  return (

    <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit}>
      <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography component="span" style={{ fontSize: 16, color: "#1E6B7F", fontWeight: 500 }}>
              { dataInactivity?.group_name ?? t('global_settings.inactivities.form.title')}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="8px">
            <button 
              type='button'
              className={style.buttonPreview}
              onClick={handleOpenModalPreview}
              >              
              <Visibility />
            </button>
            <Button
              type="button"
              style={{ minWidth: '100px', backgroundColor: "#E3EFFF", color: "#187DFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              onClick={() => {
                if(teamId === null){
                  history.push('/global-settings')
                }else{
                  history.push('/global-settings/inactivities/teams')
                }
              }}
            >
              {t('global_settings.inactivities.form.btn_cancel')}
            </Button>

            <Button
              type="submit"
              style={{ minWidth: '100px', backgroundColor: "#187DFF", color: "#FFFFFF", fontSize: '12px', borderRadius: '2px', padding: '8px 10px', textTransform: 'initial' }}
              disabled={isSubmitting}
            >
              {
                !isSubmitting
                ?
                t('global_settings.inactivities.form.btn_save')
                :
                <CircularProgress size={'21px'} color='inherit'/>
              }
            </Button>
          </Box>

        </Box>

        {
          teamId === null && (
            <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
              <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} maxWidth={'70%'}>
                <Typography component="span" className={style.titleField}>
                  {t('global_settings.inactivities.form.popup_inactivity_title')}
                </Typography>
                <Typography component="span" className={style.subtitleField}>
                  {t('global_settings.inactivities.form.popup_inactivity_subtitle')}
                </Typography>
              </Box>
            </Grid>
          )
        }

        <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
          <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'100%'}>
            <Typography component="span" className={style.titleField}>
              {t('global_settings.inactivities.form.inactivity_time_title')}
            </Typography>
            <Typography component="span" className={style.subtitleField}>
              {t('global_settings.inactivities.form.inactivity_time_subtitle')}
            </Typography>
            <Box display={'flex'} gap={"12px"} marginTop={'6px'}>
              <div className={`${style.itemMinute} ${form.inactivity_time===5 && style.active}`} onClick={() => setForm({...form, inactivity_time: 5})}>
                {`5 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
              <div className={`${style.itemMinute} ${form.inactivity_time===10 && style.active}`} onClick={() => setForm({...form, inactivity_time: 10})}>
                {`10 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
              <div className={`${style.itemMinute} ${form.inactivity_time===15 && style.active}`} onClick={() => setForm({...form, inactivity_time: 15})}>
                {`15 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
              <div className={`${style.itemMinute} ${form.inactivity_time===20 && style.active}`} onClick={() => setForm({...form, inactivity_time: 20})}>
                {`20 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
              <div className={`${style.itemMinute} ${form.inactivity_time===25 && style.active}`} onClick={() => setForm({...form, inactivity_time: 25})}>
                {`25 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
              <div className={`${style.itemMinute} ${form.inactivity_time===30 && style.active}`} onClick={() => setForm({...form, inactivity_time: 30})}>
                {`30 ${t('global_settings.inactivities.form.minutes')}`}
              </div>
            </Box>
          </Box>
        </Grid>

        <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
          <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'100%'}>
            <Typography component="span" className={style.titleField}>
              {t('global_settings.inactivities.form.message_title')}
            </Typography>
            <Typography component="span" className={style.subtitleField}>
              {t('global_settings.inactivities.form.message_subtitle')}
            </Typography>
            <Box className={style.containerFormGroup} marginBottom={'10px'}>
              <Box className={style.formGroup} width={'55%'}>
                <span className={style.fieldSubtitle}>
                  {t('global_settings.inactivities.form.field_message')}*
                </span>
                <TextField
                  name="pop_up_message"
                  error={formError.pop_up_message ? true : false}
                  helperText={`${form.pop_up_message?.length || 0} / 150`}
                  sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' } }}
                  value={form.pop_up_message}
                  onChange={handleChange}
                  placeholder={t('global_settings.inactivities.form.placeholder_message')}
                  inputProps={{
                    maxLength: 150
                  }}
                >
                </TextField>
              </Box>
              <Box className={style.formGroup} width={'45%'}>
                <span className={style.fieldSubtitle}>
                {t('global_settings.inactivities.form.field_period_absence')}
                </span>
                <TextField
                  name="absence_period_message"
                  error={false}
                  sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' } }}
                  value={form.absence_period_message}
                  onChange={handleChange}
                  disabled
                >
                </TextField>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid container style={{ padding: "14px", minHeight: "45px", display: 'flex', justifyContent: 'space-between', borderBottom: "2px solid #F5F6F9" }}>
          <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'70%'}>
            <Typography component="span" className={style.titleField}>
              {t('global_settings.inactivities.form.answers_title')}
            </Typography>
            <Typography component="span" className={style.subtitleField}>
              {t('global_settings.inactivities.form.answers_subtitle')}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'flex-end'} flexDirection={'column'} gap={'2px'} width={'30%'}>
            <BefectiveTooltip 
              title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t('global_settings.inactivities.form.tooltip_restore')}</Typography>} 
              placement="left-center"
            >
              <button className={style.buttonRestore} type='button' onClick={handleOpenConfirmRestoreOptions}>
                <ClockCounterClockwise size={20} color="#98ACC1" weight="bold" />
              </button>
            </BefectiveTooltip>
          </Box>
        </Grid>
        <Grid container>
          <table className={style.tableResponses}>
            <thead>
              <th></th>
              <th>{t('global_settings.inactivities.form.col_activity_offline')}</th>
              <th style={{width: '8%'}}>{t('global_settings.inactivities.form.col_icon')}</th>
              <th style={{width: '25%'}}>{t('global_settings.inactivities.form.col_classification')}</th>
              <th></th>
            </thead>
             <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      (form.responses && form.responses.length > 0)
                      ?
                      form.responses.map((item, index) => {
                        return (
                          <Draggable
                              key={item.id}
                              draggableId={String(item.id)}
                              index={index}
                          >
                            {(provided) => (
                              <tr 
                                key={index}
                                ref={provided && provided?.innerRef}
                                {...provided?.draggableProps}                                
                                >
                                <td style={{ cursor: 'grab', width: '0px' }} {...provided?.dragHandleProps}>
                                  <DotsSixVertical size={20} color="#98ACC1" weight="bold" />
                                </td>
                                <td>
                                  <FormControl size='small' fullWidth>
                                    <Select
                                        id={`activity_offline-${item.global_outdoors_id}`}
                                        value={item.global_outdoors_id || ""}
                                        onChange={(e) => handleChangeActivityOffline(e.target.value, index)}
                                        variant='outlined'
                                        displayEmpty 
                                        classes={{select: style[item.reference_value]}}
                                      >
                                        <MenuItem value="" disabled>
                                          {t('global_settings.inactivities.form.select')}
                                        </MenuItem>
                                        {
                                          globalOutdoors.map((item, index) => {
                                            return (
                                              <MenuItem className={style[item.reference_value]} key={`item-outdoor-${index}`} value={item.id}>{item.label}</MenuItem>
                                            )
                                          })
                                        }
                                    </Select>
                                  </FormControl>
                                </td>
                                <td>

                                  <button className={style.customSelectIcon} onClick={(e) => handleClickSelectIcon(e, {...item, aux_index:index})} type='button'>
                                    {
                                      item.url_standard
                                      ?
                                      <img 
                                        src={item.url_standard} 
                                        alt={`Icon ${index}`} 
                                      />
                                      :
                                      '--'
                                    }
                                    {
                                      !anchorElSelectIcon
                                      ?
                                      <ArrowDropDown />
                                      :
                                      <ArrowDropUp />
                                    }
                                  </button>
                                </td>
                                <td>
                                <FormControl size='small' fullWidth>
                                    <Select
                                        id={`reference_value-${item.icon_id}`}
                                        value={item.reference_value || ""}
                                        onChange={(e) => handleChangeClassification(e.target.value, index)}
                                        variant='outlined'
                                        classes={{select: style[item.reference_value]}}
                                        displayEmpty
                                      >
                                        <MenuItem value="" disabled>
                                          {t('global_settings.inactivities.form.select')}
                                        </MenuItem>
                                          <MenuItem value="productive" className={style.productive}>{t("settings.teams.related")}</MenuItem>
                                          <MenuItem value="neutral" className={style.neutral}>{t("settings.teams.neutral")}</MenuItem>
                                          <MenuItem value="unproductive" className={style.unproductive}>{t("settings.teams.not_related")}</MenuItem>
                                    </Select>
                                  </FormControl>
                                </td>
                                <td>
                                  <button className={style.buttonRemove} onClick={() => handleRemoveResponse(index)} type='button'>
                                    <Trash size={20} color="#98ACC1" weight="bold" />
                                  </button>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        )
                      })
                      :
                      <tr>
                        <td colSpan={5}>
                          {t('global_settings.inactivities.form.no_responses')}
                        </td>
                      </tr>
                    }
                    {provided.placeholder}
                    <tr>
                      <td colSpan={5}>
                        <button className={style.buttonAdd} onClick={handleAddResponse} type='button'>
                          {t('global_settings.inactivities.form.btn_add_answer')}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </Grid>
      </Box>

      <Popover
        id={idPopoverSelectIcon}
        open={openPopoverSelectIcon}
        anchorEl={anchorElSelectIcon}
        onClose={handleCloseSelectIcon}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={style.containerIcons}>
          {
            inactivityIcons.map((icon, index) => {
              return (
                <button key={`icon-popover-${index}`} className={`${style.itemIconButton} ${currentItemResponse && currentItemResponse.icon_id === icon.id && style.active}`} onClick={() => handleChangeIcon(currentItemResponse, icon)} type='button'>
                  <img 
                    
                    src={currentItemResponse && currentItemResponse.icon_id === icon.id ? icon.url_dark : icon.url_standard} 
                    alt={`Icon ${index}`} 
                  />
                </button>
              )
            })
          }
        </div>
      </Popover>

      {/* Start Confirm Dialog RestoreOptions */}
        <PopoverConfirm
          idConfirm={idConfirmRestoreOptions}
          openConfirm={openConfirmRestoreOptions}
          anchorEl={anchorElConfirmRestoreOptions}
          handleClose={handleCloseConfirmRestoreOptions}
          handleConfirm={handleConfirmRestoreOptions}
          title={t('global_settings.inactivities.form.msg_confirm_restore')}
        />
      {/* End Confirm Dialog RestoreOptions */}

      <ModalPreviewOutdoorGUI
        openModalPreviewOutdoorGUI={openModalPreview}
        handleCloseModalPreviewOutdoorGUI={handleCloseModalPreview}
        data={form}
        templateString={tamplates_strings[userDetails.language || i18n.language]}
      />
    </Box>
  )
}
