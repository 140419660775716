import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befetiveFormatCurrency, getAvatarUrl, history } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import { CardsHeader } from '../../_components/reports/value_hour/CardsHeader';
import ChartBarDay from '../../_components/reports/value_hour/ChartBarDay';
import EnhancedTable from "../../_components/_shared/EnhancedTable";
import PieHourPercent from '../../_components/reports/value_hour/PieHourPercent';
import AvgValueHour from '../../_components/reports/value_hour/AvgValueHour';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InfoHelpDrawer from '../../_components/inactivity_settings/InfoHelpDrawer';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsValueHourColabsPage(props) {

  const classes = useStyles();
  
  const {group_id} = useParams();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const lang = userDetails.language || i18n.language;
  
    const [openDrawer, setOpenDrawer] = useState(false);

  const [fetching, setFetching] = useState(true);
	const [currentMonth, setCurrentMonth] = useState(moment(new Date()).format('YYYY-MM'));	

  const [valueHourData, setValueHourData] = useState({
    teamName: null,
    stats:{
      working_day_hours: 0,
      percent_working_day_hours: 0,
      performance_hours: 0,
      percent_performance_hours: 0,
      offline_hours: 0,
      percent_offline_hours: 0,
      total_salary: 0,
      stats_avg_hour_value: 0,
    },
    data: []
  });

  const [chartData, setChartData] = useState({
    series: [{
      name: t("report_value_hour.working_day"),
      data: []
    }, {
      name: t("report_value_hour.performance"),
      data: []
    }, {
      name: t("report_value_hour.no_register"),
      data: []
    }],
    options: {
      colors: [
        "#187DFF",
        "#22BEAD",
        "#4F7091"
      ],
      chart: {
        type: 'bar',
        height: 350,
        width: "100%",
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} ${t('report_value_hour.hours')}`
          }
        }
      },
      responsive: [
        {
          breakpoint: 1024,
          options: {
            chart: { height: 300 },
            plotOptions: { bar: { columnWidth: '40%' } }
          }
        },
        {
          breakpoint: 600,
          options: {
            chart: { height: 250 },
            plotOptions: { bar: { columnWidth: '30%' } }
          }
        }
      ]
    },
  });


  const columns = useMemo(() => {
    return [
      {
        Header: t("report_value_hour.table.col_colab"),
        accessor: "username",
        Cell: ({value, row}) => {
          return (
            <div className={style.boxCellUsername}>
              <Avatar
                  alt={value}
                  src={getAvatarUrl({ name: value })}
                  style={{ width: 20, height: 20 }}
                />
              <span>{value}</span>
            </div>
          )
        }
      },
      {
        Header: t("report_value_hour.table.col_position"),
        accessor: "office",
        style: { width: '15%' }
      },
      {
        Header: t("report_value_hour.table.col_working_day_month"),
        accessor: "working_day",
        style: { width: '12%' },
        Cell: ({ value, row }) => {
          const { percent_working_day, working_day_expected } = row.original;
          return <PieHourPercent percent={percent_working_day} hours={value} total_hours={working_day_expected} type="working_day" />
        },
      },
      {
        Header: t("report_value_hour.table.col_performance"),
        accessor: "performance",
        style: { width: '12%' },
        Cell: ({ value, row }) => {
          const { percent_performance, performance_expected } = row.original;
          return <PieHourPercent percent={percent_performance} hours={value} total_hours={performance_expected} type="performance" />
        },
      },
      {
        Header: t("report_value_hour.table.col_salary_added"),
        accessor: "salary",
        style: { width: '12%' },
        Cell: ({ value, row }) => {
          return befetiveFormatCurrency(value, lang, true)
        },
      },
      {
        Header: t("report_value_hour.table.col_avg_value_hour"),
        accessor: "target_avg_hour_value",
        style: { width: '0px' },
        Cell: ({ value, row }) => {
          return <AvgValueHour lang={lang} avg_value_hour={value} avg_value_hour_percent={row.original.percent_comparative} target_avg_hour_value={row.original.target_avg_hour_value}/>
        },
      }
      
    ];
  }, []);

  

  async function getValueHourData(is_loading, month_ref = null, teammates = null) {
    
    try {
      
      if (is_loading) {
        setFetching(true)
      }

      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/hour-value/details?month_ref=${month_ref}&current_day=${moment().format('YYYY-MM-DD')}&groups=${group_id}${queryTeammates}`, { headers: authHeader() })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setValueHourData(response.data)

      const {series, categories} = response.data
      
      setChartData({
        series: [{
          name: t("report_value_hour.working_day"),
          data: series.find(s => s.name === 'working_day') ? series.find(s => s.name === 'working_day').data : []
        }, {
          name: t("report_value_hour.performance"),
          data: series.find(s => s.name === 'performance') ? series.find(s => s.name === 'performance').data : []
        }, {
          name: t("report_value_hour.no_register"),
          data: series.find(s => s.name === 'no_register') ? series.find(s => s.name === 'no_register').data : []
        }],
        options: {
          colors: [
            "#187DFF",
            "#22BEAD",
            "#4F7091"
          ],
          chart: {
            type: 'bar',
            height: 350,
            width: "100%",
            toolbar: {
              show: false,
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '50%'
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: categories.lenght > 0 ? categories.map(cat => String(moment(cat).date())) : [],
          },
          yaxis: {
            show: false,
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val} ${t('report_value_hour.hours')}`
              }
            }
          },
          responsive: [
            {
              breakpoint: 1024,
              options: {
                chart: { height: 300 },
                plotOptions: { bar: { columnWidth: '40%' } }
              }
            },
            {
              breakpoint: 600,
              options: {
                chart: { height: 250 },
                plotOptions: { bar: { columnWidth: '30%' } }
              }
            }
          ]
        },
      })
      
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  
  const handleOpenDrawerInfoHelp = () => {
    setOpenDrawer(true)
  }

  const handleCloseDrawerInfoHelp = () => {
    setOpenDrawer(false)
  } 


  useEffect(() => {

   	let date = moment(new Date()).format('YYYY-MM');
	 
		if (filter.singleDate) {
			date = moment(filter.singleDate).format('YYYY-MM')
		}

		setCurrentMonth(date)

    let teammates = null;

    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getValueHourData(true, date, teammates)

  }, [filter])

	useEffect(() => {			
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('breadcrub.value_hour');
    teammatesActions.getTeammates(group_id)
	}, [])

  return (
			<>
				<section className="mainContent">

					<Toolbar groupName={valueHourData?.teamName}/>

					<Filterbar
						filterWho={'multi'}
						filterWhenMonths={'single'}
						filterWhen={'disabled'}
						filterWhat={'disabled'}
						showShareBtn={false}
            hideTeams={true}  
            disabledFetchingTeamsAndTeammates={true}
            showButtonInfoHelp={true}
            handleOpenDrawerInfoHelp={handleOpenDrawerInfoHelp}
					/>

					<div className={style.containerPage}>
						{
							!fetching
								?
								<>
									<CardsHeader 
                    stats={valueHourData?.stats}
                    lang={lang}
                  />
                  <ChartBarDay 
                    teamName={valueHourData?.teamName}
                    month={currentMonth}
                    data={chartData}
                  />
                  <EnhancedTable
                    columns={columns}
                    data={valueHourData.data || []}
                  />
								</>
								:
								<GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
						}

					</div>

				</section>

        
        <InfoHelpDrawer
          openDrawer={openDrawer}
          closeDrawer={handleCloseDrawerInfoHelp}
        />

			</>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsValueHourColabsPage)